.manufacturer-page-img {
  margin-bottom: 10px;
}

.manufacturer-page-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
}
.manufacturer-page-logo-content {
  padding: 10px;
}

.manufacturer-container {
  width: 96%;
  margin: auto;
}