.weekly-reports-toggle-container {
  padding-top: 10px;
}
.weekly-reports-toggle-content {
  padding-top: 10px;
  display: flex;
}
.weekly-reports-toggle {
  padding-right: 10px;
}

.weekly-reports {
    min-height: 500px;
}

.weekly-reports-select-container {
  display: flex;
}
.weekly-reports-select-selector {
  flex-shrink: 0;
  flex-grow: 1;
}

.weekly-down-btn button {
  background-color: rgb(0, 102, 255);
  color: white; /* Use a contrasting text color */
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-right: 10px;
  transition: background-color 0.3s; /* Add a smooth transition for hover effect */
}

.weekly-down-btn button:hover {
  background-color: rgb(0, 71, 179);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
}

.weekly-report-calendar-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.weekly-report-daypicker {
  background: white;
}
