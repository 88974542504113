.fade-in {
  opacity: 0; /* Start with 0 opacity */
  animation: fadeInAnimation 1.25s ease-in-out forwards; /* Apply the animation */
}

.fade-cust-in {
  opacity: 0; /* Start with 0 opacity */
  animation: fadeInAnimation 2.75s ease-in-out forwards; /* Apply the animation */
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* CSS for tables */
.main-table,
.table-wrap {
  margin: auto;
}

.table-wrap {
  display: flex;
  justify-content: space-between;
}

.table-container {
  width: 44%;
  vertical-align: top;
}

.tab-container {
  width: 96%;
  margin: auto;
}

.tab-selected {
  background-color: rgba(211, 212, 218, 1.0);
}
.tab-option {
  display: inline-block;
  border: 1px solid;
  border-radius: 5px 5px 0 0;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
}

.main-table table {
  border-collapse: collapse;
  font-size: 0.8em;
  width: 100%;
  min-width: 400px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  margin-bottom: 1%;
}

.main-table th {
  position: relative;
  padding: 12px 15px;
  padding-right: 24px;
  text-align: left;
  color: #fff;
  background-color: #009879;
  font-weight: bold;
  user-select: none; /* Disable user selection on the header */
}

.main-table td {
  padding: 12px 15px;
  color: #3e3e3e;
  height: 25px;
}

.main-table td:hover {
  background-color: #ffa !important;
}

.main-table tr {
  background-color: #fff;
}

.main-table tr:nth-of-type(even) {
  background-color: #f0f2f8;
}

.di-table-header th {
  background-color: #980500;
}

.di-table-header th.hoverable:hover {
  cursor: pointer;
  background-color: #680300; /* Change this color to your desired hover color */
}


.main-table tr:last-of-type,
.di-table-header tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.di-table tr:last-of-type {
  border-bottom: 2px solid #980500;
}

.main-table tr:hover td {
  background-color: #fcfcd9;
  color: #009879;
}

.di-table tr:hover td {
  background-color: #fcfcd9;
  color: #980500;
}

.main-table th.selectedd {
  background-color: #680300 !important;
  cursor: pointer;
}

.table-name {
  color: #009879;
  padding-right: 10px;
}

.section-title {
  color: #009879;
}

.img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.main-table th .icon {
  position: absolute;
  top: 12px;
  right: 8px;
}

.hoverable-header:hover {
  background-color: #066854;
  cursor: pointer;
}

.main-table th.selected {
  background-color: #066854 !important;
  cursor: pointer;
}

.numeric-cell {
  text-align: right;
}


.user-controls-flexbox {
  display: flex;
  gap: 1%;
  margin-bottom: 1%;
}

.user-controls-element {
  display: flex;
  gap: 1%;
  margin-bottom: 1%;
  width: 100%;
}

.user-controls-tenant-select {
  flex-shrink: 0;
  flex-grow: 1;
}

.toggle-icon img,
.up-icon img {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.up-icon,
.toggle-icon {
  position: relative;
  margin-left: 5px;
  display: inline-block;
  width: 16px;
  height: 15px;
}

.table-wrapper:nth-child(2) {
  margin-left: auto; /* Move the second table to the end of the flex container */
}

.table-wrapper table {
  width: 100%;
}

/* customer_details.html filter buttons */
#btn-table {
  display: flex;
  margin: auto;
  padding: 0;
  align-items: center;
  margin-top: 1%;
}

#refresh-btn,
#btn-cust,
.calendar-btn,
#download-btn {
  margin-left: auto;
}

#refresh-btn button,
#btn-cust button,
#download-btn button,
#big-down-btn button {
  cursor: pointer;
  background: none;
  border: 0;
}

#refresh-btn button,
#btn-cust button,
#download-btn button {
  padding: 10px;
}
#big-down-btn button {
  padding: 0px;
}

.issues-download-container {
  display: flex;
  padding-right: 10px;
  align-items: center;
  margin-right: auto;
}

.issues-download-all-container {
  display: flex;
  align-items: center;
  height: 100%;
}

.issues-download-text {
  font-weight: bold;
  color: #a7a6a6;
  font-size: 1.5vh;
}

.issues-download-text {
  padding: 5px;
}

.loading-icon {
  background-image: url('../images/loading.gif');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}


#refresh-btn img {
  width: 24px;
  margin-bottom: -11px;
  padding: 5px;
}

#download-btn img {
  width: 28px;
  margin-bottom: -12px;
}


#big-down-btn img {
  width: 32px;
}

#big-down-btn img:hover,
#refresh-btn img:hover,
#download-btn img:hover {
  background-color: #dddee0;
  border-radius: 50px;
}

#btn-cust,
.calendar-btn {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}

.calendar-btn {
  margin-top: 10px;
}

#btn-cust button:hover,
#btn-cust button:focus,
#btn-cust button.selected {
  background-color: #fcbe2e;
  color: #fff;
}

.right-align {
  text-align: right;
}

h3 {
  color: #009879;
}