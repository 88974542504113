.error h1,
.error p {
  color: #404042;
  text-align: center;
  font-size: 300px;
  margin: 0;
  padding: 0;
  margin-top: 8%;
}

.error p {
  font-size: 30px;
  margin: 0;
}

.error-link {
  width: fit-content;
  margin: auto;
  margin-top: 30px; /* Optional: Add some margin to separate from the text */
}

.error-link a {
  color: #317ae2;
  font-size: 20px;
  text-decoration: none;
}

.error-link a:hover {
  text-decoration: underline;
}
