/* CSS Styling for footer below */
#foot {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #f0f2f8;
  color: white;
  text-align: left;
  padding-bottom: 280px;
  font-size: 12px;
}

#foot img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 275px;
  padding-top: 30px;
}

.container {
  max-width: 58%;
  text-align: left;
}

.foot-flex {
  padding: 20px;
  padding-top: 0;
  margin: auto;
  width: 70%;
}

.foot-box {
  float: left;
  letter-spacing: 0.3px;
  padding: 20px;
}

.foot-box div,
.foot-box li {
  margin-top: 5px;
}

.foot-box div {
  color: rgba(255, 255, 255, 0.6);
}

.foot-box h6 {
  color: #fff;
  margin-bottom: 0;
  font-size: 10px;
  letter-spacing: 1px;
}

.foot-box ul {
  list-style-type: disc;
  margin-left: -22px;
}

.foot-box li {
  display: list-item;
  text-align: inherit;
}

.foot-box a {
  text-decoration: none;
  color: #fff;
}

.foot-box a:hover {
  color: #c5c5c5;
}
