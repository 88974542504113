.customer-page-img {
  margin-bottom: 10px;
}

.customer-page-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.customer-page-logo-content {
  width: 50%;
  padding: 10px;
}

.cust-container {
  width: 96%;
  margin: auto;
}