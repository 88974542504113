body {
  margin: 0;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  background-color: #f8f8f8;
  margin: auto;
  font-weight: 100;
  margin-top: 3%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.scroll-to-top-button {
  position: fixed;
  background: #333;
  padding: 10px 13px;
  bottom: 0px;
  right: 15px;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  width: 0.65%;
  opacity: 0;
  transition: opacity 0.4s;
  z-index: 9999;
}

.scroll-to-top-button:hover {
  background: #404040;
}

.scroll-to-top-button.visible {
  opacity: 0.9;
}