/* CalendarButton component styles */
.calendar-button {
  display: inline-block;
}

/* Calendar component styles */
.calendar-wrapper {
  position: relative;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  padding: 15px;
}

.calendar-header {
  display: flex;
  margin-bottom: 1%;
}

.calendar-header h3 {
  margin: auto;
}

.calendar-header button {
  font-size: 22px;
  padding: 3px 10px !important;
  color: #000 !important;
}

.calendar-days {
  text-align: center;
  font-weight: 600;
}

.calendar-grid,
.calendar-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 4px;
}

/* Add this to your Calendar.css or create a new CSS file for styling */
.calendar-container {
  position: absolute;
  margin-top: 5px;
  margin-left: -5%;
  transition: opacity 0.3s ease, max-height 0.3s ease;
}

.calendar-container.collapsed {
  opacity: 0;
  pointer-events: none; /* Hide the calendar container from interaction when collapsed */
}

h3 {
  font-size: 150%;
  color: #fcbe2e;
}
