.cost-picker {
  min-height: 400px;
}

.billing-picker {
  font-size: 16px;
  font-weight: 100;
  padding: 3px 10px !important;
}

.billing-email-btn,
.costs-download-btn button {
  background-color: rgb(0, 102, 255);
  color: white; /* Use a contrasting text color */
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-right: 10px;
  transition: background-color 0.3s; /* Add a smooth transition for hover effect */
}

.billing-email-btn,
.costs-download-btn button:hover {
  background-color: rgb(0, 71, 179);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
}