/* CSS Styling for navbar below */
#navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border: 1px solid #e9e4e4;
  width: 100%;
  top: 0;
  position: fixed;
  z-index: 9999;
}

.links {
  display: flex;
  align-items: center;
  padding: 13px;
  letter-spacing: 0.3px;
}

.username {
  margin-right: 10px;
  letter-spacing: 0.3px;
  font-size: 17px;
  font-weight: 600;
}

.links button {
  background-color: rgb(207, 16, 16); /* Update the background color */
  color: white; /* Use a contrasting text color */
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-right: 10px;
  transition: background-color 0.3s; /* Add a smooth transition for hover effect */
}

.links button:hover {
  background-color: rgb(168, 10, 10); /* Update the hover background color */
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3); /* Add a slight box-shadow on hover */
}

.nav-link button {
  background-color: rgb(0, 102, 255);
}

.nav-link button:hover {
  background-color: rgb(0, 71, 179);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
}

/* Optional: Adjust the font weight and letter-spacing for a better look */
.links button a {
  text-decoration: none;
  color: inherit;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.logo {
  width: 13%;
}

.logo-img {
  width: 100%;
  padding: 10px;
}
