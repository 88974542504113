.login-container {
  margin-top: -3%;
}

.login-logo {
  width: 12%;
  display: block;
  margin: 1%;
  margin-top: 4%;
}

.login {
  background-color: #fff;
  margin: 7% auto;
  padding: 5px 50px 50px 50px;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  width: 21%;
}

.login h3 {
  color: #404042;
  text-align: center;
  font-size: 300%;
  margin-top: 0;
  margin-bottom: 15%;
  padding: 0;
}

.welcome {
  font-size: 20%;
  margin: 0;
  padding: 0;
}

.google-btn {
  width: fit-content;
  margin: auto;
}

.google-terms {
  margin-top: 15%;
  color: #808080;
  font-size: 70%;
}

.google-terms a {
  text-decoration: none;
  color: #317ae2;
}

.google-terms a:hover {
  text-decoration: underline;
}
